import { useEffect } from 'react';

const Braunschweig = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 426);
    localStorage.setItem('club_name', 'Eintracht Braunschweig');

    window.location.assign('/');
  });

  return null;
};
export default Braunschweig;
